<template>
  <div>
    <!-- {{ parse(mapsData[0]['kordinat']) }} -->
    <b-card class="mt-1">
      <h4 class="mb-1">Peta Sebaran Titik Koordinat Pekerjaan</h4>
      <l-map :zoom="zoom" :center="center">
        <l-tile-layer :url="url" />
        <l-geo-json :geojson="geojson" />

        <div v-for="mapMarker in mapsData" v-bind:key=mapMarker>
          <l-marker :lat-lng="mapMarker.kordinat" @click="loadData(mapMarker)">
            <l-popup v-b-modal.modal-1>{{ mapMarker.nama_kordinat }}</l-popup>
            <l-icon v-b-modal.modal-1>
              <b-img :src="require('@/assets/images/misc/marker.png')" height="20" />
            </l-icon>
          </l-marker>
        </div>

      </l-map>
    </b-card>

    <b-modal id="modal-1" hide-footer body-bg-variant="primary" header-bg-variant="primary">
      <b-card-text>
        <div>
          <div class="card text-center  bgpreseni text-white">
            <div class="card-body">
              <h4 class="text-white">Kelompok {{ dataPeta.nama_kelompok }}</h4>
              <h6 class="text-white">Rekap presensi hari ini</h6>
              <h1 class="font-weight-bolder  text-white" style=" font-size: 50px;">{{ dataPeta.jm_anggota }}</h1>
              <p class="card-text">Anggota yang presensi</p>
              <small>Koordinat {{  dataPeta.nama_kordinat}}</small><br>
              <small>Keterangan {{ dataPeta.keterangan }}</small>
            </div>
          </div>
        </div>
        <!-- {{ dataPeta }} -->
      </b-card-text>
    </b-modal>


  </div>
</template>

<script>
  var string = "-6.8246731, 110.9025722";
  var ars = string.split(",");


  import {
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BImg,
    BModal,
    BButton,
    VBModal,
  } from 'bootstrap-vue'
  import {
    LMap,
    LTileLayer,
    LMarker,
    LCircle,
    LPolygon,
    LGeoJson,
    LIcon,
    LTooltip,
    LPopup
  } from 'vue2-leaflet'
  import 'leaflet/dist/leaflet.css'
  import {
    bind
  } from 'leaflet'
  import Ripple from 'vue-ripple-directive'

  export default {
    components: {
      LMap,
      LTileLayer,
      LMarker,
      LCircle,
      LPolygon,
      LGeoJson,
      BImg,
      LTooltip,
      BModal,
      BButton,
      VBModal,
      LCircle,
      BCard,
      BCardTitle,
      BCardHeader,
      BCardBody,
      LPopup,
      LIcon
    },
    data() {
      return {
        split: ars,
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        zoom: 8,
        center: [-7.252115, 109.903769],
        circle: {
          center: [-7.252115, 109.903769],
          radius: 4500,
          color: '#EA5455',
        },
        geojson: null,
        dataPeta: {},
        mapMarker: [{
            title: "Banjarnegara",
            markerLatLng: [-7.4305599878000885, 109.53674365276154]
          },
          {
            title: "Banyumas",
            markerLatLng: [-6.993372958542134, 110.40985079732147]
          }
        ],
        markerLatLng: [-6.993372958542134, 110.40985079732147],
      }
    },
    props: {
      mapsData: {}
    },
    methods: {
      loadData(mapsData) {
        this.dataPeta = mapsData;
        this.$root.$emit('bv::show::modal', 'modal-1');
      }
    },
    async created() {
      // const response = await fetch('http://localhost:8080/jawa_tengah.json')
    const response = await fetch('https://cintalapangan.wbsantosa.com/jawa_tengah.json')
      this.geojson = await response.json()
    },
    directives: {
      'b-modal': VBModal,
      Ripple,
    },
  }
</script>

<style lang="scss">
  .vue2leaflet-map {
    &.leaflet-container {
      height: 420px;
    }
  }

  .bgpreseni {
    background: #06588fd2;
  }
</style>